import React, { Component } from 'react';
import Dropzone from 'react-dropzone-uploader'
import "./Home.css"
import './react-dropzone-uploader.css'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Qs from 'qs';
import { Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap';
import { FiCheck, FiRepeat, FiBell } from "react-icons/fi";
import { FcOk } from "react-icons/fc";

const flow_init = "init";
const flow_upload = "upload";
const flow_email = "email";
const flow_finish = "finish";

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        let uid = uuidv4();
        this.state = {
            session: uid,
            flow: flow_init,
            email: '',
            isEmailInvalid: false
        }
        var that = this;
        axios.post('/Api/CreateSession', Qs.stringify({
            uid: this.state.session
        }))
        .then(function (response) {
            console.log(response);
            that.setState({
                session: uid,
                flow: flow_upload,
                email: '',
                isEmailInvalid: false
            });
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    //
    // Uploader callbacks.
    //
    getUploadParams = (meta) => {
        return {
            url: '/Api/Upload',
            fields: { uid: this.state.session }
        }
    }

    // called every time a file's `status` changes
    handleChangeStatus = ({ meta, file }, status) => {
        console.log(status, meta, file)
        if (status === "removed" && this.state.flow === "upload") {
            console.log("Marking file ", file.name, "as deleted");
            window.dataLayer.push({
                'event': 'Converter',
                'action': 'File Chooser',
                'label': 'Delete File From List'
            });
            axios.post('/Api/DeleteFile', Qs.stringify({
                uid: this.state.session,
                filename: file.name
            }))
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        if (status === "done") {
            window.dataLayer.push({
                'event': 'Converter',
                'action': 'File Chooser',
                'label': 'Uploaded'
            });
        }
    }

    // receives array of files that are done uploading when submit button is clicked
    handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta));
        window.dataLayer.push({
            'event': 'Converter',
            'action': 'File Chooser',
            'label': 'Submit Files'
        });
        //let that = this;
        this.setState(
            {
                flow: flow_email
            },
            () => {
                allFiles.forEach(f => f.remove());
            }
        );
        
        //axios.post('/Api/Commit', Qs.stringify({
        //    uid: this.state.session
        //}))
        //.then(function (response) {
        //    console.log(response);
        //    that.setState({ session: uuidv4() });            
        //})
        //.catch(function (error) {
        //    console.log(error);
        //});
    }    

    CreateSession = (uid) => {
        //axios.post('/Api/CreateSession', Qs.stringify({
        //    uid: uid
        //}))
        //.then(function (response) {
        //    console.log(response);
        //})
        //.catch(function (error) {
        //    console.log(error);
        //});
    }

    onClickGoApprovalStudio = () => {
        window.dataLayer.push({
            'event': 'Converter',
            'action': 'Processing Files',
            'label': 'Navigate to Approval Studio Site'
        });
    }

    //
    // Flow callbacks.
    //
    onSubmitEmail = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    onEmailConfirmButtonClicked = (event) => {
        if (!this.IsEmailAddressValid(this.state.email)) {
            this.setState({ isEmailInvalid: true });
            return;
        }

        window.dataLayer.push({
            'event': 'Converter',
            'action': 'Processing Files',
            'label': 'Submit E-Mail'
        });

        this.setState({ flow: flow_finish });

        axios.post('/Api/Initiate', Qs.stringify({
            uid: this.state.session,
            email: this.state.email
        }))
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    onFinishButtonClicked = (event) => {
        window.dataLayer.push({
            'event': 'Converter',
            'action': 'Processing Files',
            'label': 'Convert More Files'
        });
        let uid = uuidv4();
        this.setState({
            session: uid,
            flow: flow_upload,
            email: '',
            isEmailInvalid: false
        });
        
        axios.post('/Api/CreateSession', Qs.stringify({
            uid: uid
        }))
        .then(function (response) {
            console.log(response);
            //this.state = {
            //    session: uid,
            //    flow: flow_upload,
            //    email: '',
            //    isEmailInvalid: false
            //}
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    onEmailBlur = (event) => {
        this.setState({ email: event.target.value, isEmailInvalid: false });
    }

    onEmailChange = (event) => {
        this.setState({ email: event.target.value, isEmailInvalid: false });
    }

    IsEmailAddressValid(email) {
        const expression = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return expression.test(email);
    }

    render() {
        let upload_class = "";
        let email_class = "d-none";
        let ok_class = "d-none";
        switch (this.state.flow) {
            case flow_upload:
                upload_class = "";
                email_class = "d-none";
                ok_class = "d-none";
                break;
            case flow_email:
                upload_class = "d-none";
                email_class = "";
                ok_class = "d-none";
                break;
            case flow_finish:
                upload_class = "d-none";
                email_class = "d-none";
                ok_class = "";
                break;
        }
        return (
            <div className="mt-5 main-section">
                <h1 className="text-center">Welcome to PDF to JPG converter, powered by Approval Studio.</h1>
                <h3 className="text-center">No Registration, no Watermarks, no Ads.</h3>
                <h3 className="text-center">Working on all platforms &mdash; PC, Mac, Android, iOS.</h3>
                <br /><p className="text-center">Follow these simple instructions to convert your file:<br />
                1) Upload and submit your PDF.<br />
                2) Enter your email address.<br/>
                3) Click a link in the email to download your JPG.</p>
                <div className={upload_class}>
                    <b>Step 1 of 2.</b>
                    <Dropzone
                        getUploadParams={this.getUploadParams}
                        onChangeStatus={this.handleChangeStatus}
                        onSubmit={this.handleSubmit}
                        accept="application/pdf"
                        addClassNames={{ submitButton: 'btn btn-primary' }}
                        />
                </div>
                <div className={email_class}>
                    <b>2 of 2 Processing</b>
                    <p>Your PDF(s) are about to be processed. Please enter your email address for getting download links with the converted files.</p>
                    <p>After submitting, the uploaded PDF(s) will be queued to process, this usually takes a couple of minutes or less</p>
                    <Form onSubmit={this.onSubmitEmail}>
                        <FormGroup>
                            <Label for="email">Your email:</Label>
                            {
                                this.state.isEmailInvalid ? 
                                    <Input type="email" name="email" id="email" placeholder="@" invalid value={this.state.email} onBlur={this.onEmailBlur} onChange={this.onEmailChange} /> :
                                    <Input type="email" name="email" id="email" placeholder="@" value={this.state.email} onBlur={this.onEmailBlur} onChange={this.onEmailChange} />
                            }                            
                            <FormFeedback>Please provide a valid e-mail adress.</FormFeedback>
                            <FormText>Note: We keep all email private and never disclose to third parties.</FormText>
                        </FormGroup>
                        <Button color="primary" onClick={this.onEmailConfirmButtonClicked}><FiCheck/> Proceed!</Button>
                    </Form>
                </div>
                <div className={ok_class} align="center">
                    <span><FcOk className="color-icon"/></span>
                    <h2>Thank you!</h2>
                    <p>You will get the email with the link to converted files once they are processed.</p>
                    <p>Meanwhile, you can take a look at <a href="https://approval.studio/" onClick={this.onClickGoApprovalStudio} target="_blank">Approval Studio</a> &mdash; ultimate review and approval tool for graphics files.</p>                    
                    <Button color="primary" onClick={this.onFinishButtonClicked}><FiRepeat /> Convert another files</Button>
                    <p className="separator-text"></p>
                    <p className="note-text"><FiBell /> Please note that the emails might go to Spam or some other folders.</p>
                </div>
                
            </div>
        );
    }
}
