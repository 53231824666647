import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    onClickGoApprovalStudio = () => {
        window.dataLayer.push({
            'event': 'Converter',
            'action': 'Top Panel',
            'label': 'Navigate to Approval Studio Site'
        });
    }

    onClickGoApprovalStudioSignUp = () => {
        window.dataLayer.push({
            'event': 'Converter',
            'action': 'Top Panel',
            'label': 'Navigate to Sign Up Page'
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand href="https://approval.studio/" onClick={this.onClickGoApprovalStudio} target="_blank"><img src="/logo.png" alt="Approval Studio" className="brand-logo" /></NavbarBrand>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem className="nav-item-try">
                                    <NavLink href="https://app.approval.studio/Account/NewUser" onClick={this.onClickGoApprovalStudioSignUp} target="_blank">Try Approval Studio</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
